import Alpine from 'alpinejs';
import Header from './app/header';
import Search from './app/search';
import Video from './app/video';
import CoverageTableLink from './app/coveragetable_link';
import ComparisonTable from './app/comparisontable';
import Global from './app/global';
import Forms from './app/forms';
import Cards from './app/cards';
import Links from './app/links';
import Intercom from './app/intercom';
import Timeline from './app/timeline';
import Glossary from './app/glossary';

// App
Header.init();
Search.init();
Video.init();
CoverageTableLink.init();
ComparisonTable.init();
Global.init();
Forms.init();
Cards.init();
Links.init();
Intercom.init();
Timeline.init();
Glossary.init();

// Alpine
window.Alpine = Alpine;
Alpine.start();
