const Helpers = {
    getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
        return false;
    },
    getOffset(el) {
        el = el.getBoundingClientRect();
        return {
            left: el.right + window.scrollX,
            top: el.top + window.scrollY,
        };
    },
    isArrayEmpty(arr) {
        if (arr === undefined || arr === null) return true;
        return arr.length === 0;
    },
    isNumeric(str) {
        return /^[0-9]+$/.test(str);
    },
    removeHTMLTags(input) {
        return input.replace(/<\/?[^>]+(>|$)/g, '');
    },
};

window.Helpers = Helpers;

export default Helpers;
