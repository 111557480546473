const Header = {
    init() {
        window.addEventListener('scroll', this.onScroll);
        document.addEventListener('alpine:init', () => {
            Alpine.data('NavigationMenu', () => Header.navigationMenu);
        });
        document.addEventListener('alpine:init', () => {
            Alpine.data('NavidationMobileMenu', () => Header.navidationMobileMenu);
        });
    },
    onScroll() {
        const navigation = document.querySelector('#navigation');
        var scroll_distance = window.top.scrollY;
        scroll_distance != 0 ? navigation.classList.add('scrolling') : navigation.classList.remove('scrolling');
    },
    navigationMenu: {
        init() {
            const nav = this.$el;
            const backdrop = document.querySelector('.dropdown-backdrop');
            const header = document.querySelector('header');
            const nav_list = document.querySelector('header .nav-list');
            const container_width = document.querySelector('.container').offsetWidth;
            let currentDropwdown = null;

            nav.querySelectorAll('.nav-item a').forEach((item) => {
                if (item.dataset.children === 'false') {
                    return;
                }
                // Mouseover event
                item.addEventListener('mouseover', (event) => {
                    currentDropwdown = event.target.dataset.navItem;
                    var dropdownItem = nav.querySelector('.dropdown-' + currentDropwdown);
                    Header.navigationMenu.showDropdown(dropdownItem, backdrop);

                    var dropdownItemHeight = dropdownItem.offsetHeight + 4; // 4px is the border width
                    var dropdownItemWidth = dropdownItem.offsetWidth + 4; // 4px is the border width

                    backdrop.style.height = dropdownItemHeight + 'px';
                    backdrop.style.width = container_width > dropdownItemWidth ? dropdownItemWidth + 'px' : container_width + 'px';

                    if (container_width < dropdownItemWidth) {
                        dropdownItem.style.setProperty('width', container_width + 'px', 'important');
                    }
                });
                // Mouseleave event
                item.addEventListener('mouseleave', (event) => {
                    if (header.contains(event.relatedTarget) && !nav_list.contains(event.relatedTarget)) {
                        return;
                    }
                    var dropdownItem = nav.querySelector('.dropdown-' + currentDropwdown);
                    Header.navigationMenu.hideDropdown(dropdownItem, backdrop);
                });
            });
            // Mouseleave event on backdrop
            header.addEventListener('mouseleave', (event) => {
                if (header.contains(event.relatedTarget) && !nav_list.contains(event.relatedTarget)) {
                    return;
                }
                var dropdownItem = nav.querySelector('.dropdown-' + currentDropwdown);
                Header.navigationMenu.hideDropdown(dropdownItem, backdrop);
            });
            backdrop.addEventListener('mouseleave', (event) => {
                if (header.contains(event.relatedTarget) && !nav_list.contains(event.relatedTarget)) {
                    return;
                }
                var dropdownItem = nav.querySelector('.dropdown-' + currentDropwdown);
                Header.navigationMenu.hideDropdown(dropdownItem, backdrop);
            });
        },
        showDropdown(dropdownItem, backdrop) {
            if (!dropdownItem || !backdrop) {
                return;
            }
            dropdownItem.classList.add('show-dropdown');
            dropdownItem.classList.remove('hide-dropdown');
            backdrop.classList.add('show-dropdown-backdrop');
            backdrop.classList.remove('hide-dropdown-backdrop');
        },
        hideDropdown(dropdownItem, backdrop) {
            if (!dropdownItem || !backdrop) {
                return;
            }
            dropdownItem.classList.remove('show-dropdown');
            dropdownItem.classList.add('hide-dropdown');
            backdrop.classList.remove('show-dropdown-backdrop');
            backdrop.classList.add('hide-dropdown-backdrop');
        },
    },
    navidationMobileMenu: {
        show_mobile_menu: false,
        show_back_button: false,
        init() {
            var that = this;
            const mobile_menu_wrapper = this.$el;
            const mobile_menu_open_button = document.querySelector('#mobile-menu-open-button');
            const mobile_menu = mobile_menu_wrapper.querySelector('.mobile-menu');
            const mobile_menu_button = mobile_menu_wrapper.querySelector('#mobile-menu-button');
            const mobile_menu_backdrop = mobile_menu_wrapper.querySelector('#mobile-menu-backdrop');
            const mobile_menu_title = mobile_menu_wrapper.querySelector('#mobile-menu-title');

            const mobile_menu_header_height = mobile_menu_wrapper.querySelector('.mobile-menu-header').offsetHeight;
            const viewport_height = window.innerHeight;

            // Open mobile menu
            mobile_menu_open_button.addEventListener('click', (event) => {
                mobile_menu.classList.add('show-mobile-menu');
                mobile_menu.classList.remove('hide-mobile-menu');
                that.show_mobile_menu = true;
                mobile_menu_backdrop.classList.add('show-mobile-menu-backdrop');
                mobile_menu_backdrop.classList.remove('hide-mobile-menu-backdrop');
                document.body.style.overflow = 'hidden';
            });

            // Close mobile menu
            mobile_menu_backdrop.addEventListener('click', (event) => {
                mobile_menu.classList.remove('show-mobile-menu');
                mobile_menu.classList.add('hide-mobile-menu');
                mobile_menu_backdrop.classList.remove('show-mobile-menu-backdrop');
                mobile_menu_backdrop.classList.add('hide-mobile-menu-backdrop');
                this.resetSlides();
                that.show_back_button = false;
                document.body.style.overflow = 'auto';
            });

            // Mobile actions button
            mobile_menu_button.addEventListener('click', (event) => {
                if (that.show_back_button) {
                    this.resetSlides();
                    that.show_back_button = false;
                    mobile_menu_title.textContent = 'Menu';
                } else {
                    mobile_menu.classList.remove('show-mobile-menu');
                    mobile_menu.classList.add('hide-mobile-menu');
                    mobile_menu_backdrop.classList.remove('show-mobile-menu-backdrop');
                    mobile_menu_backdrop.classList.add('hide-mobile-menu-backdrop');
                }
            });

            // Mobile menu items
            mobile_menu.querySelectorAll('.nav-slide-item').forEach((item) => {
                var slide = mobile_menu.querySelector('.nav-slide-' + item.dataset.navSlideItem);
                if (slide) {
                    item.addEventListener('click', (event) => {
                        var title = item.querySelector('h5').textContent.trim();
                        mobile_menu_title.textContent = title;
                        slide.classList.add('show-slide');
                        slide.classList.remove('hide-slide');
                        that.show_back_button = true;
                    });
                }
                var banner = slide.querySelector('.mobile-menu-banner');
                if (banner) {
                    var banner_height = banner.offsetHeight;
                    var links_height = slide.querySelector('.links').offsetHeight;

                    var total_height = mobile_menu_header_height + banner_height + links_height + 48;

                    if (total_height > viewport_height) {
                        banner.style.display = 'none';
                    }
                }
            });
        },
        resetSlides() {
            this.$el.querySelectorAll('.nav-slide').forEach((item) => {
                item.classList.remove('show-slide');
                item.classList.add('hide-slide');
            });
        },
    },
};

export default Header;
