import Helpers from '../utilities/helpers';

const Forms = {
    init() {
        Forms.hubspotForms();
        Forms.setInlineFormRules();
    },

    hubspotForms() {
        document.addEventListener('alpine:init', () => {
            Alpine.data('HubSpot', () => Forms.HubSpot);
        });
        Forms.addEventListenerToFields();
        window.addEventListener('message', function (event) {
            if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady') {
                Forms.addEventListenerToFields();
            }
        });
    },
    addEventListenerToFields() {
        var forms = document.querySelectorAll('form.slim');

        forms.forEach((form) => {
            var fields = form.querySelectorAll('.field');

            fields.forEach((field) => {
                var input = field.querySelector('input, textarea, select');
                if (input) {
                    input.addEventListener('blur', function () {
                        if (input.value === '') {
                            field.classList.remove('active');
                        }
                    });
                    input.addEventListener('focus', function () {
                        field.classList.add('active');
                    });
                    if (input.value !== '') {
                        field.classList.add('active');
                    }
                    if (input.tagName.toLowerCase() === 'select') {
                        var options = input.querySelectorAll('option');
                        options[0].innerHTML = '';
                    }
                }
                var label = field.querySelector('label');
                if (label && !label.classList.contains('hs-form-booleancheckbox-display')) {
                    var backgroundColor = Forms.findParentWithBackgroundColor(label);
                    if (backgroundColor) {
                        label.style.backgroundColor = backgroundColor;
                    } else {
                        input.classList.add('has-gradient-background');
                    }
                }
            });
        });
    },
    findParentWithBackgroundColor(element) {
        // Traverse up the DOM hierarchy
        while (element) {
            // Check if the current element has a background color
            var backgroundColor = window.getComputedStyle(element).backgroundColor;
            var backgroundImage = window.getComputedStyle(element).backgroundImage;

            if (backgroundColor !== 'rgba(0, 0, 0, 0)' && backgroundColor !== 'transparent') {
                return backgroundColor;
            }
            if (backgroundImage.includes('linear-gradient')) {
                return false;
            }

            // Move up to the parent element
            element = element.parentElement;
        }
        return null; // Return null if no parent with a background color is found
    },
    setInlineFormRules() {
        window.addEventListener('message', (event) => {
            if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady') {
                var forms = document.querySelectorAll('.hs-form-' + event.data.id + '.inline');
                if (forms) {
                    forms.forEach((form) => {
                        form.appendChild(form.querySelector('.legal-consent-container'));
                    });
                }
            }
        });
    },
    async fetchJson(url) {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Network response was not OK');
            }
            const json = await response.json();
            return json;
        } catch (error) {
            console.error('There has been a problem with your fetch operation:', error);
            return false;
        }
    },
    HubSpot() {
        return {
            //
            // Normal embbeded HubSpot form
            //
            form: null,
            createForm(formId, classes, theme, id, successUrl, cancelUrl) {
                var color = theme === 'white' ? '#fff' : '#000';

                var args = {
                    portalId: '3988323',
                    formId: formId,
                    cssRequired: '.submitted-message * { color: ' + color + '; }',
                    submitButtonClass: 'btn btn-' + theme + ' btn-md',
                    cssClass: theme + ' ' + classes,
                    target: '#hubspot_form_' + id,
                    formInstanceId: 'hubspot_form_' + id,
                };

                if (successUrl) {
                    args.inlineMessage = '<h5 id="inline-message">Proceeding to payment...<br></h5>';
                }

                window.hbspt.forms.create(args);
            },

            //
            // Backup form for adblocker users
            //
            response: false,
            emailIsBlocked: false,
            emailMessage: 'Please enter a different email address. This form does not accept addresses from free email domains.',
            inlineMessage: false,

            getHubSpotForm(formid) {
                //formid = '9bed0355-eb8d-496b-97f2-a673a311a4a6';
                var url = '/wp-json/hubspot/v1/form/?formid=' + formid;
                Forms.fetchJson(url).then((data) => {
                    this.form = data;
                    console.log(data);
                });
            },

            getFormData() {
                const fields = Array.from(this.$el.querySelectorAll('input:not([type="checkbox"]):not([type="radio"]), select, textarea'));
                const field_data = fields.reduce((object, key) => ({ ...object, [key.name]: key.value }), {});

                const checkboxes = Array.from(this.$el.querySelectorAll('input[type="checkbox"], input[type="radio"]'));
                const check_data = checkboxes.reduce((object, key) => {
                    if (key.checked && Helpers.isNumeric(key.id)) {
                        return { ...object, [key.id]: key.name };
                    }
                    return object;
                }, {});

                const data = {
                    fields: field_data,
                    checkboxes: check_data,
                };

                return data;
            },

            async sendForm(formid, consent_text) {
                consent_text = consent_text.privacyText ? consent_text.privacyText : consent_text;
                consent_text = consent_text.consentToProcessCheckboxLabel ? consent_text.consentToProcessCheckboxLabel : consent_text;

                this.response = await (
                    await fetch('/wp-json/hubspot/v1/form/', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            form_id: formid,
                            field_data: this.getFormData(this.$el).fields,
                            consent_data: this.getFormData(this.$el).checkboxes,
                            consent_text: consent_text,
                            page_url: window.location.href,
                            page_name: document.title,
                        }),
                    })
                ).json();
                this.checkIfBlockedEmail(this.response);

                if (this.response.redirectUri) {
                    window.location.href = this.response.redirectUri;
                }
                if (this.response.inlineMessage) {
                    this.inlineMessage = this.response.inlineMessage;
                    this.form = null;
                }
            },

            checkIfBlockedEmail(error) {
                if (error.status === 'error') {
                    error.errors.forEach((error) => {
                        if (error.errorType === 'BLOCKED_EMAIL') {
                            this.emailIsBlocked = true;
                            console.log('Email is blocked');
                        }
                    });
                }
            },
        };
    },
};

export default Forms;
